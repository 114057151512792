import React from 'react';

// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    top: '0px',
    zIndex: 22
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  backgroundColor: '#04B4FA',
  width: '100%',
  padding: theme.spacing(1, 3),
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '0',
  alignItems: 'center'
}));

const TopbarTextStyle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontFamily: 'Source Sans Pro',
  fontWeight: 700,
  color: '#ffffff',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px'
  }
}));

const TopbarTextCaptionStyle = styled(Link)(() => ({
  fontFamily: 'Source Sans Pro',
  fontWeight: 700,
  color: 'black',
  padding: '0 5px'
}));

export default function Topbar() {
  return (
    <RootStyle>
      <ContentStyle>
        <TopbarTextStyle variant="body2" align="center">
          PLEASE BE AWARE: The only group we have on Telegram is
          <TopbarTextCaptionStyle underline="none" href="#" target="_blank">
            @incomeisland
          </TopbarTextCaptionStyle>
          All other groups are fake.
        </TopbarTextStyle>
      </ContentStyle>
    </RootStyle>
  );
}
