import NProgress from 'nprogress';
import { Switch, Route } from 'react-router-dom';
import { Suspense, Fragment, lazy, useEffect, useMemo } from 'react';
// material
import { makeStyles } from '@material-ui/core/styles';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// layouts
import { Layout, DashboardLayout, LayoutwithoutWallet } from '../layouts';
// components
import LoadingScreen from '../components/LoadingScreen';
//
import {
  PATH_AUTH,
  PATH_PAGE,
  PATH_HOME,
  PATH_BUY,
  PATH_DASHBOARD,
  PATH_IGUANA
} from './paths';
// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
      }
    }
  }
}));

function RouteProgress(props) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return <Route {...props} />;
}

export function renderRoutes(routes = []) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, idx) => {
          const Component = route.component;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <RouteProgress
              key={`routes-${idx}`}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

const routes = [
  // Others Routes
  {
    exact: true,
    guard: GuestGuard,
    path: PATH_AUTH.login,
    layout: Layout,
    component: lazy(() => import('../views/authentication/Login'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: PATH_AUTH.register,
    layout: Layout,
    component: lazy(() => import('../views/authentication/Register'))
  },
  {
    exact: true,
    path: PATH_AUTH.verify,
    layout: Layout,
    component: lazy(() => import('../views/authentication/VerifyCode'))
  },
  {
    exact: true,
    path: PATH_AUTH.resetPassword,
    layout: Layout,
    component: lazy(() => import('../views/authentication/ResetPassword'))
  },
  {
    exact: true,
    path: PATH_AUTH.forgotPassword,
    layout: Layout,
    component: lazy(() => import('../views/authentication/ForgotPassword'))
  },
  {
    exact: true,
    path: PATH_PAGE.page404,
    layout: Layout,
    component: lazy(() => import('../views/Page404'))
  },
  {
    exact: true,
    path: PATH_PAGE.page500,
    layout: Layout,
    component: lazy(() => import('../views/Page500'))
  },
  {
    exact: true,
    path: PATH_PAGE.comingSoon,
    layout: Layout,
    component: lazy(() => import('../views/ComingSoon'))
  },
  {
    exact: true,
    path: '/',
    layout: Layout,
    component: lazy(() => import('../views/MainPage'))
  },
  {
    exact: true,
    path: '/shop',
    layout: Layout,
    component: lazy(() => import('../views/SubCategoryPage'))
  },
  {
    exact: true,
    path: '/download',
    layout: Layout,
    component: lazy(() => import('../views/MetaversePage'))
  },
  {
    exact: true,
    path: '/ethprivatesale/',
    layout: LayoutwithoutWallet,
    component: lazy(() => import('../views/EthPresale'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: PATH_HOME.profile,
    layout: Layout,
    component: lazy(() => import('../views/ProfilePage'))
  },
  {
    exact: true,
    path: '/buy',
    layout: Layout,
    component: lazy(() => import('../views/buy/PropertiesList'))
  },
  {
    exact: true,
    path: PATH_BUY.lands,
    layout: Layout,
    component: lazy(() => import('../views/buy/LandsList'))
  },
  {
    exact: true,
    path: PATH_BUY.detail,
    layout: Layout,
    component: lazy(() => import('../views/buy/Detail'))
  },
  {
    exact: true,
    path: PATH_IGUANA.root,
    layout: Layout,
    component: lazy(() => import('../views/iguana/Mint'))
  },
  {
    exact: true,
    path: PATH_IGUANA.detail,
    layout: Layout,
    component: lazy(() => import('../views/iguana/Result'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: PATH_DASHBOARD.root,
    layout: DashboardLayout,
    component: lazy(() => import('../views/DashboardPage'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: PATH_DASHBOARD.admins,
    component: lazy(() => import('../views/ManageAdminPage'))
  }
];
export default routes;
