import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Topbar from './Topbar';
import NavbarwithoutWallet from './NavbarwithoutWallet';
import Footer from './Footer';

// ----------------------------------------------------------------------
const RootStyle = styled(motion.div)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  backgroundColor: '#07163d',
  [theme.breakpoints.down('md')]: {
    marginTop: '9.6rem'
  }
}));

LayoutwithoutWallet.propTypes = {
  children: PropTypes.node
};

export default function LayoutwithoutWallet({ children }) {
  return (
    <RootStyle>
      <Topbar />
      <NavbarwithoutWallet />
      <MainStyle>{children}</MainStyle>
      <Footer />
    </RootStyle>
  );
}
