import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import Topbar from './Topbar';
import Navbar from './Navbar';
import Footer from './Footer';

// ----------------------------------------------------------------------
const RootStyle = styled(motion.div)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  backgroundColor: '#07163d',
  [theme.breakpoints.down('sm')]: {
    marginTop: '154px'
  }
}));

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default function DashboardLayout({ children }) {
  const { user } = useSelector((state) => state.authJwt);

  return (
    <RootStyle>
      <Topbar />
      <Navbar />
      <MainStyle>{children}</MainStyle>
      {user.role === 1 ? <></> : <Footer />}
    </RootStyle>
  );
}
