import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  experimentalStyled as styled,
  makeStyles
} from '@material-ui/core/styles';
import { Box, Container, Link } from '@mui/material';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import { useWallet } from 'use-wallet';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';

import { Icon } from '@iconify/react';
import useAuth from '../hooks/useAuth';
import Sidebar from './Sidebar';
import MyAvatar from '../components/MyAvatar';

import { MIconButton } from '../components/@material-extend';
import ConnectModal from '../components/connectModal';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  dropdown: {
    '&:hover': {
      '& .dropdown-content': {
        display: 'block'
      }
    }
  },
  active: {
    '& .menuContext:after': {
      opacity: 1
    }
  },
  menuRoot: {
    '& .MuiPaper-root': {
      padding: '1rem'
    }
  },
  menuLink: {
    textDecoration: 'none',
    color: '#fff',
    width: '100%'
  },
  dropdownLink: {
    textDecoration: 'none',
    color: 'rgba(102,102,102,0.85)',
    width: '100%',
    '&:hover': {
      color: '#CD4A42'
    }
  }
}));

const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: '#F88120',
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    top: '54px',
    zIndex: 222
  }
}));

const ContentStyle = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'flex-start'
}));

const LogoStyle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '200px',
  marginRight: '20px'
}));

const SidebarStyle = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const MenuStyle = styled('div')(() => ({
  marginLeft: 'auto',
  position: 'relative',
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));

const MenuListStyle = styled('ul')(() => ({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'flex-end'
}));

const MenuItemStyle = styled('li')(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  cursor: 'pointer',
  color: '#fff',
  alignItems: 'center',
  padding: '0 1rem'
}));

const MenuItemTextStyle = styled('div')(() => ({
  fontSize: '16px',
  fontFamily: 'Montserrat',
  color: '#fff',
  fontWeight: '600',
  textDecoration: 'none',
  display: 'inline-flex',
  flexWrap: 'wrap',
  padding: '10px 0',
  alignItems: 'center',
  '&:after': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '3px',
    opacity: 0,
    marginTop: '5px',
    background: '#04b4fa',
    transition: 'all .3s'
  },
  '&:hover::after': {
    opacity: 1
  }
}));

const DropdownStyle = styled('ul')(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  top: '100%',
  backgroundColor: '#ffffff',
  minWidth: '240px',
  padding: '.5rem 0',
  boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
  marginRight: '6rem',
  zIndex: '1',
  '&:before': {
    content: '""',
    position: 'absolute',
    borderColor: 'rgba(194, 225, 245, 0)',
    border: 'solid transparent',
    borderBottomColor: 'white',
    borderWidth: '11px',
    marginLeft: '-10px',
    top: '-21px',
    left: '74%',
    zIndex: 1
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    left: '50%',
    top: '-21px',
    width: 0,
    height: 0,
    border: 'solid transparent',
    borderWidth: '10px',
    borderBottomColor: 'none',
    zIndex: 0
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '140px',
    marginRight: '0rem',
    '&:before': {
      content: '""',
      position: 'absolute',
      borderColor: 'rgba(194, 225, 245, 0)',
      border: 'solid transparent',
      borderBottomColor: 'white',
      borderWidth: '11px',
      marginLeft: '-10px',
      top: '-21px',
      left: '50%',
      zIndex: 1
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '-21px',
      width: 0,
      height: 0,
      border: 'solid transparent',
      borderWidth: '10px',
      borderBottomColor: 'none',
      zIndex: 0
    }
  }
}));

const DropdownItemStyle = styled('li')(() => ({
  listStyle: 'none',
  transition: 'all .2s',
  color: 'rgba(102,102,102,0.85)',
  padding: '10px 20px',
  borderBottom: '1px solid #ececec',
  textDecoration: 'none',
  fontSize: '16px',
  fontFamily: 'Source Sans Pro',
  fontWeight: '400',
  display: 'block',
  margin: '0px 10px'
}));

const MetaButtonStyle = styled(RouterLink)(() => ({
  fontSize: '.85em',
  fontFamily: 'Montserrat',
  fontWeight: '600',
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#04B4FA',
  padding: '.6rem 1.2rem',
  borderRadius: '30px',
  lineHeight: '1.6',
  boxShadow: 'none',
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: '#07aef1'
  },
  textDecoration: 'none'
}));

export default function Navbar() {
  const classes = useStyles();
  const wallet = useWallet();

  const { isAuthenticated, is2FAPassed, user, logout } = useAuth();
  const [hover, setHover] = useState(false);
  const [account, setAccount] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (isAuthenticated === false || user == null) {
      return;
    }
    setOpen(true);
    // wallet.connect();
  };
  const handleClose = () => setOpen(false);

  /* eslint-disable-next-line */
  const handleConnect = async (e) => {
    e.preventDefault();

    if (wallet.status === 'disconnected') {
      handleOpen();
    } else if (wallet.status === 'error') {
      enqueueSnackbar('Please select the Binance Smart Chain main network.', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });

      setAccount(null);
      wallet.reset();
    }
  };

  /* eslint-disable-next-line */
  useEffect(async () => {
    if (
      window.web3 === null &&
      !open &&
      isAuthenticated === true &&
      user !== null
    )
      handleOpen();
    // setInterval(async () => {
    //   if (wallet.status !== 'connected') {
    //     if (!open) handleOpen();
    //   }
    // }, 3000);
    /* eslint-disable-next-line */
  }, []);

  return (
    <RootStyle>
      <Container maxWidth="xl">
        <ContentStyle id="back-to-top-anchor">
          <LogoStyle>
            <Link
              className={classes.menuLink}
              underline="none"
              href="https://incomeisland.org"
              target="_current"
            >
              <Box component="img" src="/static/home/logo.png" />
            </Link>
          </LogoStyle>
          <MenuStyle>
            <MenuListStyle sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <MenuItemStyle
                className={hover ? '' : classes.active}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle className="menuContext">
                  <Link
                    className={classes.menuLink}
                    underline="none"
                    href="https://incomeisland.org"
                    target="_current"
                  >
                    Home
                  </Link>
                </MenuItemTextStyle>
              </MenuItemStyle>
              <MenuItemStyle
                className={`${hover ? '' : classes.active} ${classes.dropdown}`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle className="menuContext">
                  Audits / KYC <IconExpandMore />
                </MenuItemTextStyle>
                <DropdownStyle className="dropdown-content">
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://tokensniffer.com/token/qzg5jczxl36eo2qiujkxxf1u100z3q6qnzjxzkzlz8zm6h2810fhq9mv53k9"
                      target="_new"
                    >
                      RENOUNCED CONTRACT
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://github.com/solidproof/kyc-certificates/raw/main/KYC_Certificate_IncomeIsland.png"
                      target="_new"
                    >
                      Search / Verify on solidproof.io
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://twitter.com/SolidProof_io/status/1466843558976139269"
                      target="_new"
                    >
                      Official Twitter
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://github.com/solidproof/kyc-certificates/raw/main/KYC_Certificate_IncomeIsland.png"
                      target="_new"
                    >
                      KYC Certificate
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://www.coinscope.co/coin/income/audit"
                      target="_new"
                    >
                      Audit 1
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://bitgert.com/audits/public/project/125"
                      target="_new"
                    >
                      Audit 2
                    </Link>
                  </DropdownItemStyle>
                </DropdownStyle>
              </MenuItemStyle>
              <MenuItemStyle
                className={`${hover ? '' : classes.active} ${classes.dropdown}`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle className="menuContext">
                  Buy <IconExpandMore />
                </MenuItemTextStyle>
                <DropdownStyle className="dropdown-content">
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://incomeisland.org/buy-income-island/"
                      target="_new"
                    >
                      Buy with Fiat or swap BNB
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://incomeisland.app/buy"
                      target="_new"
                    >
                      Buy VRE Virtual Real Estate
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://incomeisland.app/iguana"
                      target="_new"
                    >
                      Buy Iguana NFT's
                    </Link>
                  </DropdownItemStyle>
                </DropdownStyle>
              </MenuItemStyle>
              <MenuItemStyle
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle>
                  <Link
                    className={classes.menuLink}
                    underline="none"
                    href="https://incomeisland.org/calculator/?"
                    target="_new"
                  >
                    Rewards
                  </Link>
                </MenuItemTextStyle>
              </MenuItemStyle>
              <MenuItemStyle
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle>
                  <Link
                    className={classes.menuLink}
                    underline="none"
                    href="https://incomeisland.org/wp-content/uploads/2021/09/Income-Island-V1.pdf"
                    target="_new"
                  >
                    Whitepaper
                  </Link>
                </MenuItemTextStyle>
              </MenuItemStyle>
              <MenuItemStyle
                className={`${hover ? '' : classes.active} ${classes.dropdown}`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle className="menuContext">
                  Press <IconExpandMore />
                </MenuItemTextStyle>
                <DropdownStyle className="dropdown-content">
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://issuu.com/cryptoweekly/docs/crypto_weekly_magazine_nov_5_full_issue?fr=sYWI0NDQ0NjE1MzI"
                      target="_new"
                    >
                      Crypto Weekly
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://finance.yahoo.com/news/income-island-setting-standards-metaverse-182700250.html"
                      target="_new"
                    >
                      Yahoo Finance
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://www.bloomberg.com/press-releases/2021-12-13/income-island-the-blockchain-metaverse-with-mining-capability"
                      target="_new"
                    >
                      Bloomberg
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://www.nasdaq.com/press-release/income-island-photo-realistic-metaverse-edges-closer-to-release-2021-12-28"
                      target="_new"
                    >
                      Nasdaq
                    </Link>
                  </DropdownItemStyle>
                </DropdownStyle>
              </MenuItemStyle>
              {!isAuthenticated && (
                <MenuItemStyle
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <MenuItemTextStyle>
                    <Link
                      className={classes.menuLink}
                      underline="none"
                      component={RouterLink}
                      to="/auth/login"
                    >
                      Log in
                    </Link>
                  </MenuItemTextStyle>
                </MenuItemStyle>
              )}
              {!isAuthenticated && (
                <MenuItemStyle
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <MenuItemTextStyle>
                    <Link
                      className={classes.menuLink}
                      underline="none"
                      component={RouterLink}
                      to="/auth/register"
                    >
                      Register
                    </Link>
                  </MenuItemTextStyle>
                </MenuItemStyle>
              )}
              <MenuItemStyle
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle>
                  <Link
                    className={classes.menuLink}
                    underline="none"
                    component={RouterLink}
                    to="/ethprivatesale"
                  >
                    Eth Private
                  </Link>
                </MenuItemTextStyle>
              </MenuItemStyle>
              <MenuItemStyle sx={{ alignItems: 'flex-start' }}>
                <MetaButtonStyle variant="contained" to="/">
                  Metaverse
                </MetaButtonStyle>
              </MenuItemStyle>
              {isAuthenticated &&
              (user.twoFAEmail === 0 ||
                (is2FAPassed && user.twoFAEmail === 1)) ? (
                <MenuItemStyle
                  className={classes.dropdown}
                  sx={{ alignItems: 'flex-start' }}
                >
                  <MyAvatar />
                  <DropdownStyle className="dropdown-content">
                    <DropdownItemStyle>
                      <Link
                        className={classes.dropdownLink}
                        underline="none"
                        component={RouterLink}
                        to=""
                        onClick={() => setOpen(true)}
                      >
                        {account == null
                          ? 'Wallet connnect'
                          : account.substring(0, 5) +
                            '...' +
                            account.substring(
                              account.length - 3,
                              account.length
                            )}
                      </Link>
                    </DropdownItemStyle>
                    <DropdownItemStyle>
                      <Link
                        className={classes.dropdownLink}
                        underline="none"
                        component={RouterLink}
                        to="/profile"
                      >
                        Edit Profile
                      </Link>
                    </DropdownItemStyle>
                    <DropdownItemStyle onClick={logout}>
                      <Link
                        className={classes.dropdownLink}
                        underline="none"
                        onClick={logout}
                      >
                        Logout
                      </Link>
                    </DropdownItemStyle>
                  </DropdownStyle>
                </MenuItemStyle>
              ) : (
                <></>
              )}
            </MenuListStyle>
            {isAuthenticated &&
            (user.twoFAEmail === 0 ||
              (is2FAPassed && user.twoFAEmail === 1)) ? (
              <MenuListStyle sx={{ display: { xs: 'flex', sm: 'none' } }}>
                <MenuItemStyle
                  className={classes.dropdown}
                  sx={{ alignItems: 'flex-start' }}
                >
                  <MyAvatar />
                  <DropdownStyle className="dropdown-content">
                    <DropdownItemStyle>
                      <Link
                        className={classes.dropdownLink}
                        underline="none"
                        component={RouterLink}
                        to=""
                        onClick={() => setOpen(true)}
                      >
                        {account == null
                          ? 'Wallet connnect'
                          : account.substring(0, 4) +
                            '...' +
                            account.substring(
                              account.length - 2,
                              account.length
                            )}
                      </Link>
                    </DropdownItemStyle>
                    <DropdownItemStyle>
                      <Link
                        className={classes.dropdownLink}
                        underline="none"
                        component={RouterLink}
                        to="/profile"
                      >
                        Edit Profile
                      </Link>
                    </DropdownItemStyle>
                    <DropdownItemStyle>
                      <Link
                        className={classes.dropdownLink}
                        underline="none"
                        onClick={logout}
                      >
                        Logout
                      </Link>
                    </DropdownItemStyle>
                  </DropdownStyle>
                </MenuItemStyle>
              </MenuListStyle>
            ) : (
              <></>
            )}
          </MenuStyle>
          <SidebarStyle>
            <Sidebar />
          </SidebarStyle>
        </ContentStyle>
      </Container>

      <ConnectModal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        setAccount={setAccount}
        account={account}
      />
    </RootStyle>
  );
}
